<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Course") }} - {{ form.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('COURSE_UPDATE') && isEditing && (tab === 0 || tab === 1)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('COURSE_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Details") }}
          </v-tab>
          <v-tab>{{ $i18n.translate("Custom Fields") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>

      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <KeyField
                    name="courseKey"
                    label="Key"
                    id="courseKey"
                    v-model="form.courseKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'courseKey')"
                    @input="$error.clearValidationError(errors, 'courseKey')"
                  ></KeyField>
                  <v-text-field
                    name="name"
                    label="Name"
                    id="name"
                    v-model="form.name"
                    :rules="rules.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                    @input="$error.clearValidationError(errors, 'name')"
                    class="required"
                  />

                  <v-text-field
                    name="description"
                    label="Description"
                    id="description"
                    v-model="form.description"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'description')"
                    @input="$error.clearValidationError(errors, 'description')"
                  ></v-text-field>

                  <v-text-field
                    name="passingGrade"
                    label="Passing Grade"
                    id="passingGrade"
                    type="number"
                    min="0"
                    v-model="form.passingGrade"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'passingGrade')"
                    @input="$error.clearValidationError(errors, 'passingGrade')"
                  ></v-text-field>
                  <v-text-field
                    name="rankOrder"
                    label="Rank"
                    id="rankOrder"
                    type="number"
                    min="0"
                    v-model="form.rankOrder"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'rankOrder')"
                    @input="$error.clearValidationError(errors, 'rankOrder')"
                  ></v-text-field>
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  ></DateTimePickerField>
                </v-col>
                <v-col cols="6">
                  <h2>Information</h2>
                  <ul>
                    <li>
                      A <b>{{ $i18n.translate("Course") }}</b> can be a test, quiz, or learning module. Our system has
                      the capability of requiring a passing grade for Courses before Promotions can generate or pay
                      Payouts.
                    </li>
                    <li>
                      The <b>Passing Grade</b> must be a number, but depending on the setup and configuration, you can
                      define whatever passing grade that you want. By leaving the passing grade blank, a Participant
                      only needs to complete the course.
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <template v-for="(customField, index) in selectedProgram.courseCustomFields">
                    <CustomField
                      :key="customField.id"
                      v-model="form[`customFieldValue${index + 1}`]"
                      :disabled="!isEditing"
                      v-if="customField"
                      :customField="customField"
                    />
                  </template>
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-2">Course Custom Fields</h2>
                  <ol>
                    <li>
                      Configure each custom field at the Program level.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import KeyField from "@/gapp-components/components/fields/KeyField.vue";
import CustomField from "@/gapp-components/components/fields/CustomField";

export default {
  name: "Course",
  metaInfo: {
    title: "Course"
  },
  components: {
    ApiError,
    KeyField,
    DateTimePickerField,
    CustomField
  },
  data: function() {
    return {
      courseId: 0,
      tab: null,
      valid: false,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {},
      errors: {},
      rules: {
        name: [v => !!v || "Name is required"]
      }
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let serviceCall = null;

      if (this.isNew) {
        serviceCall = this.$api.post("/api/courses", this.form);
      } else {
        serviceCall = this.$api.patch("/api/courses/" + this.$route.params.id, this.form);
      }
      serviceCall
        .then(({ data }) => {
          this.form = data;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
            this.courseId = data.id;
          }
          this.fetchData();
        })
        .catch(error => {
          this.loading = false;
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api
          .get("/api/courses/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
          })
          .finally(() => {
            this.loading = false;
            this.breadcrumb();
          });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Courses",
            to: { name: "courses" },
            exact: true
          },
          { text: "New Course" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Courses",
            to: { name: "courses" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    title() {
      return "Course - " + this.form.name;
    }
  },

  mounted() {
    this.courseId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
